import {DateRangePicker, DateRangePickerValue, MultiSelect, MultiSelectItem, Select, SelectItem} from '@tremor/react';
import React, {useEffect, useState} from "react";
import {FilterId, TimeRangeValueType, TimeRangeValueTypes, useEditableFilters} from "@components/filters/FiltersContext";
import NoChartData from "@components/common/NoChartData";
import {getWordInCase} from "@components/common/dateTimeStringUtils";
import {useFilterValuesQuery} from "@services/queries";

export function DashboardFiltersView() {
    const {data, ...queryResult} = useFilterValuesQuery()
    const {timeRange, projects, issueTypes, defectTypes, scopeFilters, ...filters} = useEditableFilters()
    const [customTimeRange, setCustomTimeRange] = useState<DateRangePickerValue | undefined>(undefined)

    useEffect(() => {
        if (data) {
            filters.setIssueTypes(data.issueTypes.filter(x => x.isUserStory))
            filters.setIsLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    if (!data) {
        return <NoChartData title="Фильтры" {...queryResult} />
    }

    const allDefectTypes = data.issueTypes.filter(x => x.isDefect)

    return <div>
        {scopeFilters.includes(FilterId.TimeRange) &&
            <div className="mt-3">
                <label htmlFor="filter-timerange" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Временной период</label>
                <Select
                    id="filter-timerange"
                    className={"mt-1"}
                    value={timeRange.type}
                    onValueChange={type => filters.setTimeRange({type: type as TimeRangeValueType})}
                    defaultValue={TimeRangeValueTypes.All[0].type}
                    enableClear={false}
                >
                    {TimeRangeValueTypes.All.map(x => <SelectItem key={x.type} value={x.type}>{x.name}</SelectItem>)}
                </Select>
                {timeRange.type === 'custom' &&
                    <DateRangePicker
                        id="filter-timerange-custom"
                        className={"mt-2"}
                        placeholder={"Выберите период..."}
                        enableYearNavigation={true}
                        enableSelect={false}
                        value={customTimeRange}
                        onValueChange={v => {
                            setCustomTimeRange(v)
                            filters.setTimeRange({type: 'custom', custom: v})
                        }}
                        weekStartsOn={1}
                    />}
            </div>}

        {scopeFilters.includes(FilterId.Projects) && <div className="mt-3">
            <label htmlFor="filter-projects" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Проекты</label>
            <MultiSelect
                id="filter-projects"
                className={"mt-1"}
                value={projects.map(x => x.value)}
                onValueChange={ids => filters.setProjects(data?.projects.filter(x => ids.includes(x.value)))}
                placeholderSearch=""
                placeholder={`${data.projects.length} ${getWordInCase('проект', data.projects.length, 'nominative')}`}
            >
                {data.projects.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
            </MultiSelect>
        </div>}

        {scopeFilters.includes(FilterId.IssueTypes) && <div className={"mt-3"}>
            <label htmlFor="filter-issue-types" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Типы задач</label>
            <MultiSelect
                id="filter-issue-types"
                className={"mt-1"}
                value={issueTypes.map(x => x.value)}
                onValueChange={ids => filters.setIssueTypes(data?.issueTypes.filter(x => ids.includes(x.value)))}
                placeholderSearch=""
                placeholder={`${data.issueTypes.length} ${getWordInCase('значение', data.issueTypes.length, 'nominative')}`}
            >
                {data.issueTypes.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
            </MultiSelect>
        </div>}

        {scopeFilters.includes(FilterId.DefectTypes) && <div className={"mt-3"}>
            <label htmlFor="filter-defect-types" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Типы дефектов</label>
            <MultiSelect
                id="filter-defect-types"
                className={"mt-1"}
                value={defectTypes.map(x => x.value)}
                onValueChange={ids => filters.setDefectTypes(data?.issueTypes.filter(x => ids.includes(x.value)))}
                placeholderSearch=""
                placeholder={`${allDefectTypes.length} ${getWordInCase('значение', allDefectTypes.length, 'nominative')}`}
            >
                {allDefectTypes.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
            </MultiSelect>
        </div>}

    </div>
}