import NoChartData from "@components/common/NoChartData";
import {Metric, Title} from "@tremor/react";
import React from "react";
import {useDataSet} from "@utils/useDataSet";
import {FilterId, useFilters} from "@components/filters/FiltersContext";
import {hoursToDurationString} from "@components/common/DurationString";
import {getKpiColorInv} from "@utils/misc";

export function DefectsAgeSnapshotView() {
    const title = "Средний возраст незакрытых дефектов"
    const filters = useFilters()
    const {data, ...queryResult} = useDataSet({
        dataset: 'issue',
        filters: {
            ...filters.getFilterSpec([FilterId.Projects, FilterId.DefectTypes]),
            includeItemsStartedBefore: true,
            includeItemsFinishedAfter: true,
            openedOnly: true,
            defectsOnly: true,
        },
        select: [
            {name: 'avg_issue_age_hrs'}
        ],
    })

    if (!data || !data.data.length)
        return <NoChartData title={title} {...queryResult} isEmpty={data && !data.data.length} />

    const [avgIssueAgeHrs] = data.data[0] as number[]

    return <>
        <Title>{title}</Title>
        <Metric className={`${getKpiColorInv(avgIssueAgeHrs / 24, 20, 40)} mt-1`}>{hoursToDurationString(avgIssueAgeHrs)}</Metric>
    </>
}