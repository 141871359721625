import {Duration} from "moment";

export type DurationStringProps = {
    duration: Duration
}

export function hoursToDurationString(totalHours: number) {
    return daysToDurationString(totalHours / 24)
}

export function daysToDurationString(totalDays: number) {
    const days = Math.floor(totalDays)
    const hours = Math.floor((totalDays - days) * 24)
    const minutes = Math.round((totalDays - days - hours * 24) * 24 * 60)

    let str = ''
    if (days > 0) {
        str += `${days} д.`
    }

    if (days < 50) {
        if (hours > 0) {
            if (str) {
                str += ' '
            }
            str += `${hours} ч.`
        }

        if (days === 0 && minutes > 0) {
            if (str) {
                str += ' '
            }
            str += `${minutes} мин.`
        }
    }

    return str
}

export default function DurationString({duration}: DurationStringProps) {
    return <span>{daysToDurationString(duration.asDays())}</span>
}