import React from 'react';
import {Flex, Title} from "@tremor/react";
import {useDataSet} from "@utils/useDataSet";
import NoChartData from "@components/common/NoChartData";
import {EChartsReact} from "@components/common/EChartsReact";
import {FilterId, useFilters} from "@components/filters/FiltersContext";

type DefectsReportedFixedCumChartProps = {
    showLegend?: boolean
}

export function DefectsReportedFixedCumChart({showLegend = true}: DefectsReportedFixedCumChartProps) {
    const title = "Дефекты: заведенно/закрыто с накопительным итогом"
    const filters = useFilters()
    const {data: response, ...queryResult} = useDataSet({
        dataset: 'issue_in_status',
        select: [
            {name: 'issue_status_from_trunc', alias: 'ts', args: [{name: 'trunc_to', value: 'month'}]},
            {name: 'created_issues_count_cum', alias: 'reported_cum'},
            {name: 'resolved_issues_count_cum', alias: 'resolved_cum'},
        ],
        filters: {
            ...filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.DefectTypes]),
        },
        orderBy: [
            {name: 'ts'},
        ]
    })

    if (!response || !response.data.length)
        return <NoChartData title={title} {...queryResult} isEmpty={response && !response.data.length} />

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Title className="mb-3">{title}</Title>
            <EChartsReact
                className="w-full h-full flex-grow"
                option={{
                    grid: {
                        left: 10,
                        right: 5,
                        top: showLegend ? 30 : 10,
                        bottom: 0,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'time',
                    },
                    yAxis: [
                        {
                            type: 'value',
                        },
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: 'В работе',
                            data: response.data.map(x => [new Date((x[0] as number) * 1000), (x[1] as number) - (x[2] as number)]),
                            stack: 'x',
                        },
                        {
                            type: 'bar',
                            name: 'Закрыто',
                            data: response.data.map(x => [new Date((x[0] as number) * 1000), x[2] as number]),
                            stack: 'x',
                        },
                    ],
                    legend: showLegend ? {} : undefined,
                    tooltip: [
                        {
                            trigger: 'axis',
                            valueFormatter: (x) => `${Number((x as number).toFixed(1))}`
                        }
                    ],
                }}
            />
        </Flex>
    )
}