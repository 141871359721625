import React from 'react';
import * as echarts from 'echarts/core';
import {
    LineChart,
    BarChart,
    PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    //LegendScrollComponent,
    //LegendPlainComponent,
    DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    DatasetComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import {EChartsReactCore} from "@components/common/EChartsReact/EChartsReactCore";
import {EChartsReactProps} from "./EChartsReact/types";

// Register the required components
echarts.use(
    [
        TitleComponent,
        TooltipComponent,
        GridComponent,
        BarChart,
        CanvasRenderer,
        DataZoomComponent,
        LineChart,
        DatasetComponent,
        LegendComponent,
        PieChart
    ]
);

export function EChartsReact(props: EChartsReactProps) {
    return <EChartsReactCore
        loadingOption={{
            text: '',
            ...props.loadingOption
        }}
        echarts={echarts}
        notMerge={true}
        lazyUpdate={false}
        ref={props.innerRef}
        {...props}
    />
}