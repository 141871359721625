import React from 'react';
import {graphic} from 'echarts'
import {MarkLine1DDataItemOption} from "echarts/types/src/component/marker/MarkLineModel";
import {BadgeDelta, Flex, Title} from "@tremor/react";
import NoChartData from "@components/common/NoChartData";
import {daysToDurationString} from "@components/common/DurationString";
import {EChartsReact} from "@components/common/EChartsReact";
import {IssueMeasureDynamicsData, IssueMeasureDynamicsDataItem, IssueMeasureSnapshotData, useIssueMeasureDynamicsDataSet, useIssueMeasureSnapshotDataSet} from "./issueDataSources";

type LeadTimeMeasure = 'leadTime' | 'cycleTime' | 'completedCount' | 'completedEstimate'

export type LeadTimeDynamicsChartProps = {
    measure: LeadTimeMeasure,
}

const measureConfig = {
    leadTime: {
        title: 'Lead time в динамике',
        seriesName: 'Lead time (дни)',
        seriesData: (data: IssueMeasureDynamicsDataItem) => data.leadTimeDays,
        markLine: {
            value: (snapshotResponse: IssueMeasureSnapshotData) =>
                snapshotResponse.leadTimeDays,
            label: (snapshotResponse: IssueMeasureSnapshotData) =>
                `${snapshotResponse.percentile}-й перцентиль (${daysToDurationString(snapshotResponse.leadTimeDays)})`,
            diffBadge: {
                isIncreasePositive: false,
                tooltip: 'Отклонение в последнем месяце',
            },
        }
    },
    cycleTime: {
        title: 'Cycle time в динамике',
        seriesName: 'Cycle time (дни)',
        seriesData: (data: IssueMeasureDynamicsDataItem) => data.cycleTimeDays,
        markLine: {
            value: (snapshot: IssueMeasureSnapshotData) => snapshot.cycleTimeDays,
            label: (snapshot: IssueMeasureSnapshotData) => `${snapshot.percentile}-й перцентиль (${daysToDurationString(snapshot.cycleTimeDays)})`,
            diffBadge: {
                isIncreasePositive: false,
                tooltip: 'Отклонение в последнем месяце'
            },
        },
    },
    completedCount: {
        title: 'Завершенные задачи в динамике',
        seriesName: 'Кол-во завершенных задач',
        seriesData: (data: IssueMeasureDynamicsDataItem) => data.completedCount,
        markLine: {
            value: (snapshot: IssueMeasureSnapshotData, data: IssueMeasureDynamicsData) => data.items.length > 0 ? snapshot.completedCount / data.items.length : null,
            label: (snapshot: IssueMeasureSnapshotData, data: IssueMeasureDynamicsData) => {
                const value = data.items.length > 0 ? snapshot.completedCount / data.items.length : null
                return `Среднее число завершенных задач за весь период: ${Number(value?.toFixed(1))}`
            },
            diffBadge: {
                isIncreasePositive: true,
                tooltip: 'Отклонение в последнем месяце'
            },
        },
    },
    completedEstimate: {
        title: 'Выполнено story points в динамике (объем выполненной работы)',
        seriesName: 'Сумма оценок',
        seriesData: (data: IssueMeasureDynamicsDataItem) => data.completedEstimate,
        isIncreasePositive: true,
        markLine: {
            value: (snapshot: IssueMeasureSnapshotData, data: IssueMeasureDynamicsData) => data.items.length > 0 ? snapshot.completedEstimate / data.items.length : null,
            label: (snapshot: IssueMeasureSnapshotData, data: IssueMeasureDynamicsData) => {
                const value = data.items.length > 0 ? snapshot.completedEstimate / data.items.length : null;
                return `Velocity: ${Number(value?.toFixed(1))} SP / месяц`
            },
            diffBadge: {
                isIncreasePositive: true,
                tooltip: 'Отклонение в последнем месяце'
            },
        },
    }
}

export function LeadTimeDynamicsChart({measure}: LeadTimeDynamicsChartProps) {
    const {data: snapshot, ...snapshotQueryResult} = useIssueMeasureSnapshotDataSet()
    const {data: response, ...queryResult} = useIssueMeasureDynamicsDataSet()
    const {seriesName, title, seriesData, markLine} = measureConfig[measure]

    if (!snapshot)
        return <NoChartData title={title} {...snapshotQueryResult} />

    if (!response)
        return <NoChartData title={title} {...queryResult} />


    const data = response.items.map(x => ([
        new Date(x.ts * 1000),
        seriesData(x)
    ]))

    const {baseValue, increase} = getIncrease();

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Flex justifyContent="between" alignItems="center">
                <Title className="my-0">{title}</Title>
                {increase && markLine.diffBadge && <BadgeDelta tooltip={markLine.diffBadge.tooltip}
                    deltaType={increase > 0 ? "increase" : "decrease"}
                    isIncreasePositive={markLine.diffBadge.isIncreasePositive}
                    size="xs">
                    {increase}%
                </BadgeDelta>}
            </Flex>
            <EChartsReact
                className="mt-3 h-full w-full"
                option={{
                    grid: {
                        left: 50,
                        right: 10,
                        top: 10,
                        bottom: 20
                    },
                    xAxis: {
                        type: 'time',
                    },
                    yAxis: {
                        type: 'value',
                    },
                    tooltip: {
                        trigger: 'axis',
                        valueFormatter: x => Number((x as number).toFixed(1)).toString()
                    },
                    series: [
                        {
                            name: seriesName,
                            type: 'line',
                            areaStyle: {
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(60, 130, 246, 0.5)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgb(60, 130, 246, 0)'
                                    }])
                            },
                            smooth: true,
                            data: data,
                            markLine: baseValue !== undefined
                                ? {
                                    symbol: [],
                                    animation: false,
                                    silent: true,
                                    data: [
                                        {
                                            yAxis: baseValue,
                                            label: {
                                                formatter: markLine!.label(snapshot, response),
                                                position: 'insideStartTop'
                                            }
                                        } as MarkLine1DDataItemOption,
                                    ]
                                } : undefined
                        },
                    ],
                }}
            />
        </Flex>
    );

    function getIncrease() {
        if (!snapshot || !response)
            return {increase: undefined, basValue: undefined}

        const baseValue = markLine.value(snapshot, response)
        if (baseValue === null)
            return {increase: undefined, basValue: undefined}

        const last = seriesData(response.items[response.items.length - 1])

        return {
            baseValue: baseValue,
            increase: Math.round(1000 * (last - baseValue) / baseValue) / 10
        }
    }
}
