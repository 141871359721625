import React from 'react';
import {EChartsReact} from "@components/common/EChartsReact";

export default function ProjectsRadarChart() {
    return (
        <EChartsReact
            className="h-full w-full"
            option={{
                legend: {
                    data: ['AC', 'DSA', 'PCABO3']
                },
                radar: {
                    //shape: 'polygon',
                    shape: 'circle',
                    indicator: [
                        { name: 'Lead Time', max: 5 },
                        { name: 'Cycle Time', max: 5 },
                        { name: 'Velocity', max: 5 },
                        { name: 'Частота релизов', max: 5 },
                        { name: 'Результативность спринтов', max: 5 },
                        { name: 'Качество', max: 5 }
                    ]
                },
                tooltip: {},
                series: [
                    {
                        type: 'radar',
                        data: [
                            {
                                value: [5, 5, 3, 3, 3, 3],
                                name: 'AC'
                            },
                            {
                                value: [3, 3, 4, 4, 4, 4],
                                name: 'DSA'
                            },
                            {
                                value: [2, 2, 4, 5, 4, 4],
                                name: 'PCABO3'
                            }
                        ]
                    }
                ],
                grid: {
                    left: 5,
                    right: 5,
                    top: 30,
                    bottom: 40,
                    containLabel: true,
                }
            }}
        />
    )
}
