import {Bold, Metric, Text, Title} from "@tremor/react";
import {RiInformation2Line} from "@remixicon/react";
import Tooltip, {useTooltip} from "@tremor/react/dist/components/util-elements/Tooltip/Tooltip";
import {hoursToDurationString} from "@components/common/DurationString";
import NoChartData from "@components/common/NoChartData";
import {usePrMeasureSnapshotDataSet} from "./prDataSources";
import {getKpiColorInv} from "@utils/misc";

export function ReleaseFrequencySnapshotView() {
    const title = "Release Frequency"
    const {data, ...queryResult} = usePrMeasureSnapshotDataSet()
    const {tooltipProps, getReferenceProps} = useTooltip();

    if (!data || !data.prAverageLifetimeHours)
        return <NoChartData title={title} isEmpty={data && !data.prAverageLifetimeHours} {...queryResult} />

    const frequencyString = "N"

    return (
        <div style={{opacity: 0.5}}>
            {/* <Tooltip text={<div style={{ width: 320 }} className={"text-wrap"}>
                <span>с {data.percentile}% вероятностью в следующем месяце вы сделаете </span>
                <span className={"text-nowrap"}>{frequencyString} релизов</span>
            </div> as any} {...tooltipProps} /> */}
            <Title>{title}</Title>
            <Metric
                className={`${getKpiColorInv(data.prAverageLifetimeHours, 6 * 24, 12 * 24)} mt-1`}>{frequencyString}</Metric>
            <Text>релизов было совершено за указанные промежуток.
                {/* <sup><span style={{ baselineShift: 'super' }} ref={tooltipProps.refs.setReference} {...getReferenceProps}>
                    <RiInformation2Line style={{ width: 16, height: 16, display: 'inline' }} />
                </span></sup> */}
            </Text>
            <Text className={"mt-3"}>Данная метрика соответствует количеству релизных джобов за месяц.</Text>
        </div>
    );
}

