export function ToggleSideBarLeftImage() {
    return <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" className="">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.29 11.29L11.29 7.29C11.5437 7.03634 11.9134 6.93728 12.2599 7.03012C12.6064 7.12297 12.877 7.39362 12.9699 7.74012C13.0627 8.08663 12.9637 8.45634 12.71 8.71L10.41 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H10.41L12.71 15.29C12.8993 15.4778 13.0058 15.7334 13.0058 16C13.0058 16.2666 12.8993 16.5222 12.71 16.71C12.5222 16.8993 12.2666 17.0058 12 17.0058C11.7334 17.0058 11.4778 16.8993 11.29 16.71L7.29 12.71C7.19896 12.6149 7.12759 12.5028 7.08 12.38C6.97998 12.1365 6.97998 11.8635 7.08 11.62C7.12759 11.4972 7.19896 11.3851 7.29 11.29ZM4 4C4.55229 4 5 4.44772 5 5V19C5 19.5523 4.55229 20 4 20C3.44772 20 3 19.5523 3 19V5C3 4.44772 3.44772 4 4 4Z"
              fill="currentColor"></path>
    </svg>
}

export function ToggleSideBarRightImage() {
    return <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" className="">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.71 11.29L11.71 7.29C11.4563 7.03634 11.0866 6.93728 10.7401 7.03012C10.3936 7.12297 10.123 7.39362 10.0301 7.74012C9.93728 8.08663 10.0363 8.45634 10.29 8.71L12.59 11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H12.59L10.29 15.29C10.1007 15.4778 9.9942 15.7334 9.9942 16C9.9942 16.2666 10.1007 16.5222 10.29 16.71C10.4778 16.8993 10.7334 17.0058 11 17.0058C11.2666 17.0058 11.5222 16.8993 11.71 16.71L15.71 12.71C15.801 12.6149 15.8724 12.5028 15.92 12.38C16.02 12.1365 16.02 11.8635 15.92 11.62C15.8724 11.4972 15.801 11.3851 15.71 11.29ZM19 4C18.4477 4 18 4.44772 18 5V19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4Z"
              fill="currentColor"></path>
    </svg>
}

export function FilterImage() {
    return <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" aria-hidden="true" focusable="false" className="">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.69241 18.976C9.69241 19.895 10.417 20.64 11.3109 20.64H12.9293C13.8232 20.64 14.5478 19.895 14.5478 18.976C14.5478 18.057 13.8232 17.312 12.9293 17.312H11.3109C10.417 17.312 9.69241 18.057 9.69241 18.976ZM3.21856 4C2.32472 4 1.6001 4.74501 1.6001 5.664C1.6001 6.58299 2.32472 7.328 3.21856 7.328H21.0216C21.9155 7.328 22.6401 6.58299 22.6401 5.664C22.6401 4.74501 21.9155 4 21.0216 4H3.21856ZM6.45548 12.32C6.45548 13.239 7.1801 13.984 8.07394 13.984H16.1663C17.0601 13.984 17.7847 13.239 17.7847 12.32C17.7847 11.401 17.0601 10.656 16.1663 10.656H8.07394C7.1801 10.656 6.45548 11.401 6.45548 12.32Z"
              fill="currentColor"></path>
    </svg>
}