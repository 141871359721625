import {createContext, ReactNode, useState} from "react";
import {UserDto} from "@services/api";

type Props = {
    children?: ReactNode
}

export type IAuthContext = {
    user: UserDto | null
    setUser: (user: UserDto) => void
}

const initialAuthContext : IAuthContext = {
    user: null,
    setUser: _ => { }
}

const AuthContext = createContext(initialAuthContext)

const AuthProvider = ({children}: Props) => {
    const [user, setUser] = useState(initialAuthContext.user)

    return (
        <AuthContext.Provider value={{user, setUser}}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }