import React, { Component } from 'react';

export class NotFoundPage extends Component<{}> {
  render() {
    return (
        <p>404 Not found</p>
    );
  }
}

export default NotFoundPage;
