import {api} from "./api";

export type WorkScopeForecastChartDataResponse = {
    chart: WorkScopeForecastChartDto
    workInitial: number
    issuesInitial: number
    workSeries: WorkScopeForecastChartDataWorkSeriesDto[]
}

export type WorkScopeForecastChartDataWorkSeriesDto = {
    intervalStart: number
    intervalStartLocal: string
    workDone: number
    issuesDone: number
    workAdded: number
    issuesAdded: number
}

export type WorkScopeIssueSelectorDto = {
    projects: string[]
    issueTypes: string[]
    epics: string[]
}

export type WorkScopeForecastChartBaseDto = {
    name: string
    issueSelector: WorkScopeIssueSelectorDto
    backlogStart?: number
    start: number
    interval: string
}

export type CreateWorkScopeForecastChartDto = WorkScopeForecastChartBaseDto

export type WorkScopeForecastChartDto = WorkScopeForecastChartBaseDto & {
    id: string
    savedChartMaxDate?: number
    scopeFinishedAt?: number
}

export type UpdateWorkScopeForecastChartDto = Partial<WorkScopeForecastChartDto>

export type UpdateWorkScopeForecastChartInternalDto = UpdateWorkScopeForecastChartDto & {
    setSavedChartMaxDate?: boolean
    setScopeFinishedAt?: boolean
}

export class WorkScopeForecastChartApi {
    public async getData(id: string, end: number | null = null) {
        const query = end ? new URLSearchParams({end: end.toString()}) : undefined
        return await api.get<WorkScopeForecastChartDataResponse>(`charts/workScopeForecast/${id}/chartData`, query)
    }

    public async list() {
        return await api.get<WorkScopeForecastChartDto[]>(`charts/workScopeForecast`)
    }

    public async create(chart: CreateWorkScopeForecastChartDto) {
        return await api.invoke<WorkScopeForecastChartDto>('POST', `charts/workScopeForecast`, {
            body: chart
        })
    }

    public async update(chart: UpdateWorkScopeForecastChartDto) {
        return await api.invoke<WorkScopeForecastChartDto>('PATCH', `charts/workScopeForecast/${chart.id}`, {
            body: {
                ...chart,
                setScopeFinishedAt: true,
                setSavedChartMaxDate: true,
            } as UpdateWorkScopeForecastChartInternalDto
        })
    }

    public async delete(id: string) {
        return await api.send('DELETE', `charts/workScopeForecast/${id}`)
    }
}

export const workScopeForecastChartApi = new WorkScopeForecastChartApi()