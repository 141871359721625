import React from 'react';
import styled from "styled-components";
import {Subtitle, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Title} from "@tremor/react";
import {from} from "linq-to-typescript";
import {DataValue} from "@services/dataSetApi";
import {addIntervalToDate} from "@utils/dateUtils";
import {getDimensionColor} from "@utils/colorPaletteUtils";
import {useDataSet} from "@utils/useDataSet";
import {maxOrUndef} from "@utils/numericUtils";
import {translateIntervalNameToShortString} from "@components/common/dateTimeStringUtils";
import NoChartData from "@components/common/NoChartData";
import {hoursToDurationString} from "@components/common/DurationString";
import {FilterId, useFilters} from "@components/filters/FiltersContext";
import {useStatusUpdateQuery} from "./CumulativeFlowDiagramChart";
import {getKpiColor, getKpiColorInv} from "@utils/misc";

type StatusBreakdownSummaryTableProps = {
    interval?: 'week' | 'month' | 'day'
}

export const StyledTableHeaderCell = styled(TableHeaderCell)`
        &&&&& {
            padding: 3px 1rem !important;
        }`

export const StyledTableHeaderCellNoSidePadding = styled(TableHeaderCell)`
      &&&&& {
        padding: 3px 1px !important;;
      }`

export const StyledTableCell = styled(TableCell)`
        &&&&& {
            padding: 3px 1rem !important;;
        }`

export function NumericTableCell({value, formatter, maxValue, color = 'tremor-brand'}: {value: number | undefined, formatter?: (v: number | undefined) => string, maxValue?: number, color?: string}) {
    const hasCellBar = maxValue && value && maxValue > 0
    const percentage = hasCellBar ? Math.round(value * 100.0 / maxValue) : 0

    /*
      from-tremor-brand/10 from-yellow-500/10 from-green-400/10 from-red-400/10
      to-tremor-brand/20 to-yellow-500/20 to-green-400/20 to-red-400/20
    */
    return <StyledTableCell className='relative'>
        {hasCellBar && <div
            className={`absolute top-[2px] left-[2px] bg-no-repeat bg-gradient-to-r from-${color}/10 to-${color}/20 h-[calc(100%-4px)]`}
            style={{width: `calc(${percentage}% - 4px)`}}>
        </div>}
        {formatter ? formatter(value) : (value === undefined ? '' : value.toString())}
    </StyledTableCell>
}

const StyledTableCellNoSidePadding = styled(TableCell)`
        &&&&& {
            padding: 3px 1px !important;
        }`

export const useIssueStatusDurationAvg = () => {
    const filters = useFilters()
    return useDataSet({
        dataset: 'issue_in_status_grouped_by_issue',
        select: [
            {name: 'issue_status_name'},
            {name: 'issue_status_hrs_avg'},
            {name: 'issue_status_sort_order'},
        ],
        filters: filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.IssueTypes]),
        orderBy: [
            {name: 'issue_status_sort_order'}
        ]
    })
}

export function StatusBreakdownSummaryTable({interval = 'day'}: StatusBreakdownSummaryTableProps) {
    const title = "Метрики потока выполнения задач"
    const filters = useFilters().getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.IssueTypes])
    const {data: response, ...queryResult} = useStatusUpdateQuery(interval, filters)
    const {data: statusTimeData} = useDataSet({
        dataset: 'issue_in_status_grouped_by_issue',
        select: [
            {name: 'issue_status_name'},
            {name: 'issue_status_hrs_avg'},
            {name: 'issue_status_sort_order'},
        ],
        filters,
        orderBy: [
            {name: 'issue_status_sort_order'}
        ]
    })

    if (!response || !response.data.length)
        return <NoChartData title={title} {...queryResult} isEmpty={response && !response.data.length} />

    const
        getTs = (x: DataValue[]) => x[0] as number,
        getDiffSumCum = (x: DataValue[]) => x[1] as number,
        getSortOrder = (x: DataValue[]) => x[2] as number,
        getStatusName = (x: DataValue[]) => x[3] as string,
        getInSum = (x: DataValue[]) => x[4] as number,
        getOutSum = (x: DataValue[]) => x[5] as number,
        getIsFinal = (x: DataValue[]) => x[6] as boolean

    const minDate = new Date((response.data[0][0] as number) * 1000)
    const maxDate = new Date((response.data[response.data.length - 1][0] as number) * 1000)
    const statuses = from(response.data)
        .select(x => ({name: getStatusName(x), order: getSortOrder(x)}))
        .distinct((x, y) => x.name === y.name && x.order === y.order)
        .orderByDescending(x => String(x.order).padStart(4, '0'))
        .select(x => x.name)
        .toArray()

    const ds: (string | Date | number)[][] = []
    ds.push(['date', ...statuses])

    const generateDates = function* () {
        let date = minDate
        let row = 1
        yield {date: new Date(date.getTime()), row}
        while (date <= maxDate) {
            yield {date: new Date(addIntervalToDate(date, interval)), row: ++row}
        }
    };

    const joined = from(generateDates())
        .groupJoin(from(response.data),
            x => x.date.getTime(),
            x => getTs(x) * 1000,
            (x, y) => ({
                date: x.date,
                row: x.row,
                dsValues: y.reduce((acc, v) => {
                    acc[getStatusName(v)] = getDiffSumCum(v);
                    return acc
                }, {} as {[key: string]: number}),
            }))

    for (const {date, row, dsValues} of joined) {
        const values: number[] = []
        statuses.forEach((status, i) => {
            const dsVal = dsValues[status]
            values.push(dsVal ? dsVal : (row <= 1 ? 0 : ds[row - 1][i + 1] as number))
        })
        ds.push([date, ...values])
    }

    const intervalShort = translateIntervalNameToShortString(interval)
    const intervalCount = ds.length - 1

    const tableData: Array<{
        statusName: string
        arrivalRate: number
        outRate?: number
        wip?: number,
        statusDurationHrs?: number
        isFinal: boolean
    }> = from(response.data)
        .groupBy(x => getStatusName(x))
        .select(x => ({
            statusName: x.key,
            isFinal: getIsFinal(x.first()),
            statusOrder: getSortOrder(x.first()),
            arrivalRate: x.sum(r => getInSum(r) || 0) / intervalCount,
            outRate: x.sum(r => getOutSum(r) || 0) / intervalCount,
        }))
        .orderBy(x => String(x.statusOrder).padStart(4, '0'))
        .toArray()

    const reducedDiffSumCum = from(ds).skip(1).aggregate(new Array(ds[0].length - 1) as Array<number>, (acc, x) => {
        for (let i = 1; i < x.length; i++) {
            acc[i - 1] = (acc[i - 1] || 0) + (x[i] as number)
        }
        return acc
    })

    for (let i = 0; i < reducedDiffSumCum.length; i++) {
        const status = statuses[i]
        let val = tableData.find(x => x.statusName === status)
        if (val) {
            val.wip = reducedDiffSumCum[i] / intervalCount
        }
    }

    if (statusTimeData && statusTimeData.data.length > 0) {
        for (let i = 0; i < statusTimeData.data.length; i++) {
            const row = statusTimeData.data[i]
            const status = row[0] as string
            let val = tableData.find(x => x.statusName === status)
            if (val) {
                val.statusDurationHrs = row[1] as number
            }
        }
    }

    const tableDataMax = tableData.reduce((acc, v) => {
        acc.arrivalRate = Math.max(acc.arrivalRate, v.arrivalRate)
        acc.outRate = maxOrUndef(acc.outRate, v.isFinal ? undefined : v.outRate)
        acc.wip = maxOrUndef(acc.wip, v.isFinal ? undefined : v.wip)
        acc.statusDurationHrs = maxOrUndef(acc.statusDurationHrs, v.isFinal ? undefined : v.statusDurationHrs)
        return acc
    },
        {arrivalRate: 0} as {
            arrivalRate: number
            outRate?: number
            wip?: number,
            statusDurationHrs?: number
        })

    return <>
        <Title>{title}</Title>
        <Subtitle>Приведены <b>средние</b> значения задач в определенных статусах</Subtitle>
        <Table className="mt-2">
            <TableHead>
                <TableRow>
                    <StyledTableHeaderCellNoSidePadding style={{width: 0}}></StyledTableHeaderCellNoSidePadding>
                    <StyledTableHeaderCell style={{width: 0}}>Статус</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Вход. поток зад./{intervalShort}</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Выход. поток зад./{intervalShort}</StyledTableHeaderCell>
                    <StyledTableHeaderCell>WiP зад./{intervalShort}</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Время в статусе</StyledTableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map(x =>
                    <TableRow key={x.statusName}>
                        <StyledTableCellNoSidePadding>
                            <svg width={10} height={8} viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg">
                                <rect width="10" height="8" fill={getDimensionColor('issue_status_name', x.statusName)} />
                            </svg>
                        </StyledTableCellNoSidePadding>
                        <StyledTableCell>{x.statusName}</StyledTableCell>
                        <NumericTableCell
                            value={x.arrivalRate}
                            maxValue={tableDataMax.arrivalRate}
                            formatter={v => v!.toFixed(2)}
                        />
                        <NumericTableCell
                            value={x.isFinal ? undefined : x.outRate}
                            maxValue={tableDataMax.outRate}
                            formatter={v => x.isFinal ? '-' : (v || 0).toFixed(2)}
                        />
                        <NumericTableCell
                            value={x.isFinal ? undefined : x.wip}
                            maxValue={tableDataMax.wip}
                            formatter={v => x.isFinal ? '-' : (v || 0).toFixed(2)}
                        />
                        <NumericTableCell
                            value={x.isFinal ? undefined : x.statusDurationHrs}
                            maxValue={tableDataMax.statusDurationHrs}
                            formatter={v => x.isFinal ? '-' : (v === undefined ? '' : hoursToDurationString(v))}
                            color={x.isFinal || !x.statusDurationHrs ? undefined : getKpiColorInv(x.statusDurationHrs / 24, 5, 10, '')}
                        />
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </>
}
