import './App.css';
import React, {useContext, useEffect, useState} from 'react';
import {Route, Navigate, Outlet, createRoutesFromElements, createBrowserRouter, RouterProvider, useRouteError} from "react-router-dom"
import {QueryClient, QueryClientProvider} from 'react-query';
import {Flex} from "@tremor/react";
import {AimgerHeader} from "@components/AimgerHeader";
import TremorExample from "@pages/DashboardPage";
import DashboardPage from "@pages/DashboardPage";
import {AuthContext, AuthProvider} from "@components/AuthContext";
import {api, RedirectToAuthError} from "@services/api";
import {Redirect} from "@utils/Redirect";
import NotFoundPage from "@pages/NotFoundPage";

const TenantMasterPage = () => {
    return <Flex className="h-full" flexDirection="col">
        <AimgerHeader />
        <main className="mt-1 flex-grow w-full h-full overflow-auto">
            <Outlet/>
        </main>
    </Flex>
}

const NoTenant = () => {
    //return <Redirect to='https://aimger.ru' />
    return <Redirect to='/demo'/>
}

const RequireAuth = () => {
    const { user, setUser } = useContext(AuthContext)
    const [ error, setError ] = useState<string | null>(null)

    useEffect(() => {
        (async () => {
            if (!user) {
                try {
                    setUser(await api.getUser())
                    setError(null)
                } catch (err) {
                    if (err instanceof RedirectToAuthError) {
                        // ignore
                    } else if ((err as DOMException)?.name === 'AbortError') {
                        setError('Server request error (timeout)')
                    } else {
                        console.error(err)
                        setError('Server request error')
                    }
                }
            }
        })()
    }, [user, setUser, setError])

    if (user) {
        return <Outlet/>
    } else if (error) {
        return <div>{error}</div>
    } else {
        return <div>Authenticating...</div>
    }
}

const ErrorBoundary = () => {
    let error = useRouteError();

    if (error instanceof RedirectToAuthError) {
        return <div>Redirecting to authentication provider...</div>;
    }

    console.error(error);

    const message = error instanceof Error
        ? error.message
        : 'Unknown error'

    return <div>Error occurred: {message}</div>;
}

const queryClient = new QueryClient()

const App = () => {
    const router = createBrowserRouter(createRoutesFromElements(
        <Route errorElement={<ErrorBoundary/>}>
            <Route index element={<NoTenant/>}/>
            <Route path=":tenant" element={<TenantMasterPage/>}>
                <Route element={<RequireAuth/>}>
                    <Route path="" element={<Navigate to='dashboard' replace/>}/>
                    <Route path="dashboard" element={<DashboardPage/>}/>
                    <Route path="test" element={<TremorExample/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Route>
            </Route>
            <Route path="*" element={<NoTenant/>}/>
        </Route>
    ));

    return (
        <QueryClientProvider client={queryClient}>
        <AuthProvider>
            <RouterProvider router={router}/>
        </AuthProvider>
        </QueryClientProvider>
    )
}

export default App;
