import {useTenant} from "@services/tenants";
import React, {useContext, useState} from "react";
import {AuthContext} from "@components/AuthContext";
import {Link} from "react-router-dom";
import {RiCloseLine, RiLogoutBoxRLine, RiMenuLine, RiUser3Fill} from "@remixicon/react";

export function AimgerHeader() {
    const tenant = useTenant()
    const userContext = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false)

    return <header className="shadow-sm w-full bg-gray-100">
        <div className="px-4 py-2 flex flex-col md:flex-row max-md:gap-y-2 md:justify-between md:items-center">
            <div>
                {/* Logo */}
                <Link to={`/${tenant}/dashboard`}>
                    <div className="flex items-center gap-2">
                        <img src="/aimger-logo.png" alt="Aimger logo" className="w-8 h-8" />
                        <span className="text-gray-700 text-xl">Aimger</span>
                    </div>
                </Link>

                <div className="w-7 h-7 absolute right-4 top-3 cursor-pointer md:hidden" onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <RiCloseLine /> : <RiMenuLine />}
                </div>

                {/* Nav links (TBD) */}
            </div>
            {/* User menu */}
            {userContext.user &&
                <div className={`flex items-center gap-2 transition-all ease-in-out duration-300 ${isOpen ? '' : 'max-md:hidden'}`}>
                    <RiUser3Fill className="w-5 h-5 text-gray-700" />
                    <span className="text-sm">{userContext.user.name}</span>
                    <a href={`/${tenant}/api/auth/logout`} title="Выйти">
                        <RiLogoutBoxRLine className="ml-0.5 w-6 h-6" />
                    </a>
                </div>
            }
        </div>
    </header>
}