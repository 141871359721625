import {Bold, Metric, Text, Title} from "@tremor/react";
import {RiInformation2Line} from "@remixicon/react";
import Tooltip, {useTooltip} from "@tremor/react/dist/components/util-elements/Tooltip/Tooltip";
import {daysToDurationString} from "@components/common/DurationString";
import {getWordInCase} from "@components/common/dateTimeStringUtils";
import NoChartData from "@components/common/NoChartData";
import {useIssueMeasureDynamicsDataSet, useIssueMeasureSnapshotDataSet} from "./issueDataSources";
import {getKpiColor, getKpiColorInv} from "@utils/misc";

export function CompletedIssuesSnapshotView() {
    const title = "Завершено за весь период"
    const {data: snapshot, ...snapshotQueryResult} = useIssueMeasureSnapshotDataSet()
    const {data: dynamics, ...dynamicsQueryResult} = useIssueMeasureDynamicsDataSet()

    if (!snapshot)
        return <NoChartData title={title} {...snapshotQueryResult} />

    if (!dynamics)
        return <NoChartData title={title} {...dynamicsQueryResult} />

    const avg = snapshot.completedCount / dynamics.items.length
    const avgRounded = Number(avg.toFixed(1));

    const task = getWordInCase('задача', snapshot.completedCount, 'nominative')


    return (
        <>
            <Title>{title}</Title>
            <Metric className="text-tremor-metric mt-1">{snapshot.completedCount}<span className={"text-tremor-default font-normal"}> {getWordInCase('задача', snapshot.completedCount, 'nominative')}</span></Metric>
            <Title className="mt-3">В среднем завершается</Title>
            <Metric className="mt-1"><span className={`${getKpiColor(avg, 5, 12)}`}>{avgRounded}</span><span className={"text-tremor-default font-normal"}> {getWordInCase('задача', avgRounded, 'nominative')} в месяц</span></Metric>
        </>
    );
}

export function CompletedEstimateSnapshotView() {
    const title = "Выполнено за весь период"
    const {data: snapshot, ...snapshotQueryResult} = useIssueMeasureSnapshotDataSet()
    const {data: dynamics, ...dynamicsQueryResult} = useIssueMeasureDynamicsDataSet()
    const {tooltipProps, getReferenceProps} = useTooltip();

    if (!snapshot)
        return <NoChartData title={title} {...snapshotQueryResult} />

    if (!dynamics)
        return <NoChartData title={title} {...dynamicsQueryResult} />

    const avg = snapshot.completedEstimate / dynamics.items.length

    return <>
        <Tooltip text={"Учтены только оцененные задачи"} {...tooltipProps} />
        <Title>Выполнено за весь <span className={"text-nowrap"}>период
            <sup>
                <span style={{baselineShift: 'super'}} ref={tooltipProps.refs.setReference} {...getReferenceProps}>
                    <RiInformation2Line style={{width: 16, height: 16, display: 'inline'}} />
                </span>
            </sup>
        </span>
        </Title>
        <Metric className="text-tremor-metric mt-1">{snapshot.completedEstimate}<span className={"text-tremor-default font-normal"}> SP
            ({getWordInCase('стори поинт', snapshot.completedEstimate, 'nominative')})</span></Metric>
        <Title className="mt-3">Velocity</Title>
        <Metric className="text-tremor-metric"><span className={getKpiColor(avg, 20, 40)}>{Number(avg.toFixed(1))}</span><span className={"text-tremor-default font-normal"}> SP / месяц</span></Metric>
    </>
}

export function LeadTimeSnapshotView() {
    const title = "Lead time"
    const {data, ...queryResult} = useIssueMeasureSnapshotDataSet()
    const {tooltipProps, getReferenceProps} = useTooltip();

    if (!data || !data.leadTimeDays)
        return <NoChartData title={title} isEmpty={data && !data.leadTimeDays} {...queryResult} />

    const durationString = daysToDurationString(data.leadTimeDays)

    return (
        <>
            <Tooltip text={<div style={{width: 320}} className={"text-wrap"}>
                <span>с {data.percentile}% вероятностью следующие задачи завершатся менее чем за </span>
                <span className={"text-nowrap"}>{durationString}</span>
            </div> as any} {...tooltipProps} />
            <Title>{title}</Title>
            <Metric
                className={`${getKpiColorInv(data.leadTimeDays, 30, 40)} mt-1`}>{durationString}</Metric>
            <Text>или меньше потребовалось, чтобы <Bold>полностью завершить</Bold> каждую из <Bold>{data.percentile}%</Bold> задач.
                <sup><span style={{baselineShift: 'super'}} ref={tooltipProps.refs.setReference} {...getReferenceProps}>
                    <RiInformation2Line style={{width: 16, height: 16, display: 'inline'}} />
                </span></sup>
            </Text>
            <Text className={"mt-3"}>Lead time соответствует времени, прошедшему с момента взятия задачи в работу до момента ее закрытия.</Text>
        </>
    );
}

export function CycleTimeSnapshotView() {
    const title = "Cycle time"
    const {data, ...queryResult} = useIssueMeasureSnapshotDataSet()
    const {tooltipProps, getReferenceProps} = useTooltip();

    if (!data || !data.cycleTimeDays)
        return <NoChartData title={title} isEmpty={data && !data.cycleTimeDays} {...queryResult} />

    const durationString = daysToDurationString(data.cycleTimeDays)

    return (
        <>
            <Tooltip text={<div style={{width: 320}} className={"text-wrap"}>
                <span>с 85% вероятностью фаза разработки следующих задач займет менее чем </span>
                <span className="text-nowrap">{durationString}</span>
            </div> as any} {...tooltipProps} />
            <Title>{title}</Title>
            <Metric className={`${getKpiColorInv(data.cycleTimeDays, 10, 20)} mt-1`}>{durationString}</Metric>
            <Text>или меньше потребовалось на <Bold>разработку</Bold> каждой из <Bold>{data.percentile}%</Bold> задач.
                <sup><span style={{baselineShift: 'super'}} ref={tooltipProps.refs.setReference} {...getReferenceProps}>
                    <RiInformation2Line style={{width: 16, height: 16, display: 'inline'}} />
                </span></sup>
            </Text>
            <Text className={"mt-3"}>Cycle time соответствует времени нахождения задачи в статусах, связанных непосредственно с разработкой.</Text>
        </>
    );
}
