import {createContext, ReactNode, useContext, useState} from "react";
import {FilterValueDto, IssueTypeFilterValueDto} from "@services/dataSetApi";
import {FilterSpecDto} from "@services/sharedApiModels";
import {from} from "linq-to-typescript";
import {DateRangePickerValue} from "@tremor/react";
import {toLocalISODateTimeString} from "@components/common/dateTimeStringUtils";

type Props = {
    children?: ReactNode
}

export enum FilterId {
    TimeRange = 'timeRange',
    Projects = 'projects',
    IssueTypes = 'issueTypes',
    DefectTypes = 'defectTypes'
}

enum FilterKind {
    TimeRange = 'TimeRange',
    MultiSelect = 'MultiSelect',
}

type FilterDef = {
    id: FilterId
    name: string
    kind: FilterKind,
    applyFilter: (spec: FilterSpecDto, ctx: IFilterContextValues) => FilterSpecDto
}

export const filterDefs: FilterDef[] = [
    {
        id: FilterId.TimeRange,
        name: 'Временной период',
        kind: FilterKind.TimeRange,
        applyFilter: (spec, filters) => {
            const [from, to] = getTimeRange(filters.timeRange)

            spec.timeRange = {
                start: format(from),
                end: format(to),
            }

            return spec

            function format(date?: Date): string | undefined {
                if (date !== undefined)
                    return toLocalISODateTimeString(date)
            }
        }
    },
    {
        id: FilterId.Projects,
        name: 'Проекты',
        kind: FilterKind.MultiSelect,
        applyFilter: (spec, filters) => {
            spec.projectIds = filters.projects.map(x => x.value)
            return spec
        }
    },
    {
        id: FilterId.IssueTypes,
        name: 'Типы задач',
        kind: FilterKind.MultiSelect,
        applyFilter: (spec, filters) => {
            spec.issueTypeIds = filters.issueTypes.map(x => x.value)
            return spec
        }
    },
    {
        id: FilterId.DefectTypes,
        name: 'Типы дефектов',
        kind: FilterKind.MultiSelect,
        applyFilter: (spec, filters) => {
            spec.issueTypeIds = filters.defectTypes.map(x => x.value)
            spec.defectsOnly = true
            return spec
        }
    },
]

const filterDefsById = from(filterDefs).toObject(x => x.id)

export type TimeRangeValueType =
    'lastYear' |
    'lastQuarter' |
    'lastMonth' |
    'lastWeek' |
    'allTime' |
    'custom'

export type TimeRangeValueTypeDef = {
    type: TimeRangeValueType
    name: string
}

export class TimeRangeValueTypes {
    static readonly All: TimeRangeValueTypeDef[] = [
        { type: 'allTime', name: 'Все время' },
        { type: 'lastYear', name: 'Последний год' },
        { type: 'lastQuarter', name: 'Последняя четверть' },
        { type: 'lastMonth', name: 'Последний месяц' },
        { type: 'lastWeek', name: 'Последния неделя' },
        { type: 'custom', name: 'Указанный период' },
    ]
}

export type TimeRangeValue = {
    type: TimeRangeValueType
    custom?: DateRangePickerValue
}

export function getTimeRange(rangeVal: TimeRangeValue): [Date?, Date?] {
    const from = new Date(new Date().toDateString())
    switch(rangeVal.type) {
        case 'allTime':
            return [undefined, undefined]
        case 'custom':
            return [rangeVal.custom?.from, rangeVal.custom?.to]
        case 'lastYear':
            from.setFullYear(from.getFullYear() - 1)
            break;
        case 'lastQuarter':
            from.setMonth(from.getMonth() - 3)
            break;
        case 'lastMonth':
            from.setMonth(from.getMonth() - 1)
            break;
        case 'lastWeek':
            from.setDate(from.getDate() - 7)
            break;
    }

    return [from, new Date(new Date().toDateString())]
}

type IFilterContextValues = {
    isLoaded: boolean
    timeRange: TimeRangeValue
    projects: FilterValueDto[]
    issueTypes: IssueTypeFilterValueDto[]
    defectTypes: IssueTypeFilterValueDto[]
}

export type IFiltersContext = IFilterContextValues & {
    getFilterSpec: (filters: FilterId[]) => FilterSpecDto
}

export type IScopeFiltersContext = {
    scopeFilters: FilterId[]
    setScopeFilters: (filters: FilterId[]) => void
}

type IEditableFilterContext = IFiltersContext & {
    setIsLoaded: (val: boolean) => void
    setTimeRange: (timeRange: TimeRangeValue) => void
    setProjects: (projects: FilterValueDto[]) => void
    setIssueTypes: (issueTypes: IssueTypeFilterValueDto[]) => void
    setDefectTypes: (issueTypes: IssueTypeFilterValueDto[]) => void
}

const initialContext : IEditableFilterContext & IScopeFiltersContext = {
    isLoaded: false,
    timeRange: { type: 'lastYear' },
    scopeFilters: [],
    projects: [],
    issueTypes: [],
    defectTypes: [],
    setIsLoaded: () => {},
    setTimeRange: () => {},
    setScopeFilters: () => {},
    setProjects: () => {},
    setIssueTypes: () => {},
    setDefectTypes: () => {},
    getFilterSpec: () => ({})
}

const FiltersContext = createContext(initialContext)

export const FiltersProvider = ({children}: Props) => {
    const [isLoaded, setIsLoaded] = useState(initialContext.isLoaded)
    const [timeRange, setTimeRange] = useState(initialContext.timeRange)
    const [scopeFilters, setScopeFilters] = useState(initialContext.scopeFilters)
    const [projects, setProjects] = useState(initialContext.projects)
    const [issueTypes, setIssueTypes] = useState(initialContext.issueTypes)
    const [defectTypes, setDefectTypes] = useState(initialContext.defectTypes)

    return (
        <FiltersContext.Provider value={{isLoaded, setIsLoaded, timeRange, setTimeRange, projects, setProjects, issueTypes, setIssueTypes, getFilterSpec, scopeFilters, setScopeFilters, defectTypes, setDefectTypes}}>
            {children}
        </FiltersContext.Provider>
    )

    function getFilterSpec(filters: FilterId[]): FilterSpecDto {
        return filters.reduce((spec, filterId) => filterDefsById[filterId].applyFilter(spec,{
            isLoaded,
            timeRange,
            projects,
            issueTypes,
            defectTypes
        }), {} as FilterSpecDto)
    }
}

export function useFilters() {
    return useContext(FiltersContext) as IFiltersContext
}

export function useEditableFilters() {
    return useContext(FiltersContext)
}

export function useFiltersScope() {
    return useContext(FiltersContext) as IScopeFiltersContext
}