import {useQuery} from "react-query";
import {dataSetApi, DataSetResultDto, QueryRequest} from "@services/dataSetApi";
import {useFilters} from "@components/filters/FiltersContext";
import {QueryFunctionContext, QueryKey} from "react-query/types/core/types";
import {UseQueryOptions, UseQueryResult} from "react-query/types/react/types";
import {useEffect} from "react";

export const useDataSet = ({isEmpty, ...query}: QueryRequest & { isEmpty?: boolean }) => {
    const {isLoaded: filtersLoaded} = useFilters()

    const result = useQuery({
        queryKey: ['dataset_query', query, isEmpty, filtersLoaded],
        queryFn: async ({signal}) => isEmpty || !filtersLoaded
            ? {data: [], dataSetName: 'dataSetName', columns: [],} as DataSetResultDto
            : await dataSetApi.query(query, signal),
        refetchOnWindowFocus: false,
        meta: query.queryMeta,
        //staleTime: 300000,
    })

    return {
        ...result,
        // override fields in case we need to simulate loading query
        isLoading: result.isLoading || !filtersLoaded,
        data: filtersLoaded ? result.data : undefined,
    }
}
