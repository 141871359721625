import './DashboardPage.css'
import {Card, Col, Grid, Title} from "@tremor/react";
import BurnDownChartWithOptions from "@components/charts/workScopeForecast/BurnDownChartWithOptions";
import {LeadTimeDynamicsChart} from "@components/charts/valueDelivery/IssueMeasureDynamicsChart";
import LeadTimeDistributionChart from "@components/charts/valueDelivery/LeadTimeDistributionChart";
import {CompletedIssuesTableView} from "@components/charts/valueDelivery/CompletedIssuesTableView";
import {SprintMeasurePlanFactChart} from "@components/charts/sprints/SprintMeasurePlanFactChart";
import {DefectsReportedFixedChart} from "@components/charts/defects/DefectsReportedFixedChart";
import {DefectsReportedFixedCumChart} from "@components/charts/defects/DefectsReportedFixedCumChart";
import {StatusBreakdownSummaryTable} from "@components/charts/flowAnalisys/StatusBreakdownSummaryTable";
import {StatusTimeBreakdownSummaryChart} from "@components/charts/flowAnalisys/StatusTimeBreakdownSummaryChart";
import {CumulativeFlowDiagramChart} from "@components/charts/flowAnalisys/CumulativeFlowDiagramChart";
import {
    CompletedEstimateSnapshotView,
    CompletedIssuesSnapshotView,
    CycleTimeSnapshotView,
    LeadTimeSnapshotView
} from "@components/charts/valueDelivery/IssueMeasureSnapshotView";
import {OngoingIssuesTableView} from "@components/charts/ongoingIssues/OngoingIssuesTableView";
import {OngoingIssuesCountView} from "@components/charts/ongoingIssues/OngoingIssuesCountView";
import {OngoingDefectsSnapshotView} from "@components/charts/ongoingIssues/OngoingDefectsSnapshotView";
import {DefectsAgeSnapshotView} from "@components/charts/ongoingIssues/DefectsAgeSnapshotView";
import ProjectsRadarChart from "@components/charts/radars/ProjectsRadarChart";
import {PrAverageLifetimeSnapshotView} from "@components/charts/valueDelivery/PrMeasureSnapshotView";
import {PrLifetimeDynamicsChart} from "@components/charts/valueDelivery/PrMeasureDynamicsChart";
import {ReleaseFrequencySnapshotView} from "@components/charts/valueDelivery/ReleaseFrequencySnapshotView";


export function SummaryTabContent() {
    return <Grid className="gap-3 mt-3 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
        <Col numColSpan={1}>
            <Card className={"h-full"}>
                <LeadTimeSnapshotView />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="leadTime" />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "25rem"}}>
                <StatusTimeBreakdownSummaryChart />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "25rem"}}>
                <DefectsReportedFixedCumChart showLegend={false} />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card className={"h-full"}>
                <CompletedIssuesSnapshotView />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="completedCount" />
            </Card>
        </Col>
        <Col numColSpanMd={2}>
            <Card style={{height: "25rem"}}>
                <CumulativeFlowDiagramChart compact={true} />
            </Card>
        </Col>
    </Grid>
}

export function ValueDeliveryTabContent() {
    return <Grid numItems={1} className="gap-3 mt-3 xl:grid-cols-4">
        <Col>
            <Card className={"h-full"}>
                <LeadTimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="leadTime" />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "25rem"}}>
                <LeadTimeDistributionChart measure={"leadTime"} />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <CycleTimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="cycleTime" />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "25rem"}}>
                <LeadTimeDistributionChart measure={"cycleTime"} />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <PrAverageLifetimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <PrLifetimeDynamicsChart measure="prAverageLifetime" />
            </Card>
        </Col>

        <Col className={"xl:col-span-4"}>
            <Title>Скоро в вашем дашборде:</Title>
        </Col>

        <Col>
            <Card className={"h-full"}>
                <ReleaseFrequencySnapshotView />
            </Card>
        </Col>

    </Grid>
}

export function TeamVelocityTabContent() {
    return <Grid className="gap-3 mt-3 grid-cols-1 xl:grid-cols-4">
        <Col>
            <Card className={"h-full"}>
                <CompletedIssuesSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="completedCount" />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <CompletedEstimateSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="completedEstimate" />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-4">
            <Card>
                <CompletedIssuesTableView className="w-full table-fixed" />
            </Card>
        </Col>
    </Grid>
}

export function OngoingIssuesTabContent() {
    return <Grid className="gap-3 mt-3 grid-cols-1 xl:grid-cols-3">
        <Col>
            <Card className={"h-full"}>
                <OngoingIssuesCountView />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <OngoingDefectsSnapshotView />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <DefectsAgeSnapshotView />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-3">
            <Card>
                <OngoingIssuesTableView pageSize={20} className="w-full table-fixed" />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-3">
            <Card>
                <OngoingIssuesTableView defects pageSize={20} className="w-full table-fixed" />
            </Card>
        </Col>
    </Grid>
}

export function SprintsTabContent() {
    return <Grid numItems={1} className="gap-3 mt-3">
        <Col>
            <Card className="h-[640px]">
                <SprintMeasurePlanFactChart measure="issuesCount" />
            </Card>
        </Col>
        <Col>
            <Card className="h-[640px]">
                <SprintMeasurePlanFactChart measure="estimate" />
            </Card>
        </Col>
    </Grid>
}

export function ExecutionFlowTabContent() {
    return <Grid numItems={1} className="gap-3 mt-3">
        {/*<Col numColSpan={1}>*/}
        {/*    <Card style={{height: "20rem"}}>*/}
        {/*        /!*<CompletedIssueStatusTimeSummaryChart />*!/*/}
        {/*        <StatusTimeBreakdownSummaryChart />*/}
        {/*    </Card>*/}
        {/*</Col>*/}
        {/*<Col numColSpan={1}>*/}
        {/*    <Card style={{height: "30rem"}}>*/}
        {/*        <StatusTimeBreakdownBarChart />*/}
        {/*    </Card>*/}
        {/*</Col>*/}
        <Col>
            <Card>
                <StatusBreakdownSummaryTable interval={'day'} />
            </Card>
        </Col>
        <Col>
            <Card style={{height: "50rem"}}>
                <CumulativeFlowDiagramChart />
            </Card>
        </Col>
    </Grid>
}

export function QualityTabContent() {
    return <Grid numItemsSm={1} numItemsLg={2} className="gap-3 mt-3">
        <Col numColSpan={1}>
            <Card style={{height: "30rem"}}>
                <DefectsReportedFixedChart />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "30rem"}}>
                <DefectsReportedFixedCumChart />
            </Card>
        </Col>
    </Grid>
}

export function ScopeForecastTabContent() {
    return <Grid className="gap-3 mt-3 sm:grid-cols-2 xl:grid-cols-4">
        <Col numColSpan={4}>
            <BurnDownChartWithOptions />
        </Col>
    </Grid>
}

export function AggregatedStatsTabContent() {
    return <Grid className="gap-3 mt-3 sm:grid-cols-2 xl:grid-cols-4">
        <Col numColSpan={4} style={{height: "40rem"}}>
            <ProjectsRadarChart />
        </Col>
        <Col numColSpan={4}>
            <Title>Сводка по целям</Title>
        </Col>
        <GoalsList />
    </Grid>
}



type Goal =
    {
        name: string
        metric: string
        currentValue: number
        minValue: number,
        targetValue: number
        lowerIsBetter: boolean
        status: "reached" | "failed" | "in_progress"
        dueIn: number
    }

function GoalsList() {
    const goals: Goal[] = [
        {
            name: "Подключить три новые страны в приложении",
            metric: "Количество завершенных задач из списка",
            minValue: 0,
            currentValue: 3,
            targetValue: 3,
            lowerIsBetter: false,
            status: "reached",
            dueIn: 72
        },
        {
            name: "Lead Time за последний квартал не превышает 20 д.",
            metric: "Lead Time",
            currentValue: 25,
            minValue: 40,
            targetValue: 20,
            lowerIsBetter: true,
            status: "failed",
            dueIn: -2,
        },
        {
            name: "Увеличить покрытие Unit тестами до 70% по проекту DSA",
            metric: "% покрытия",
            currentValue: 55,
            minValue: 0,
            targetValue: 70,
            lowerIsBetter: false,
            status: "in_progress",
            dueIn: 205,
        },
        {
            name: "Сократить время загрузки приложения до 2 секунд PCABO3",
            metric: "Время загрузки, сек.",
            currentValue: 5,
            minValue: 6,
            targetValue: 2,
            lowerIsBetter: true,
            status: "in_progress",
            dueIn: 53,
        },
        {
            name: "Запустить новую маркетинговую кампанию ATOC",
            metric: "Маркетинговая компания запущена",
            currentValue: 1,
            minValue: 0,
            targetValue: 1,
            lowerIsBetter: false,
            status: "reached",
            dueIn: 22,
        }
    ]

    return <>
        {goals.map((g, i) =>
            <Col key={`goal${i}`}>
                <Card className={`bg-white rounded-lg shadow-lg border-l-4 ${getBorderColor(g)}`}>
                    <h2 className="text-xl font-semibold">{g.name}</h2>
                    <ul className="mt-2 text-gray-600">
                        <li className="tooltip rel">Текущее значение: <span className="font-bold">{g.currentValue} / {g.targetValue}</span>
                            <span className="tooltiptext">{g.metric}</span>
                        </li>
                        {g.dueIn >= 0
                            ? <li>Осталось дней: <span className="font-bold">{g.dueIn}</span></li>
                            : <li>Просрочено дней: <span className="font-bold text-red-400">{-g.dueIn}</span></li>}

                    </ul>
                    <div className="relative pt-1 mt-4 tooltip">
                        <div className={`overflow-hidden h-4 mb-4 text-xs flex rounded ${getBgColor(g, true)}`}>
                            <div style={{width: `${getPercentage(g)}%`}} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${getBgColor(g)}`}></div>
                        </div>
                        <span className="tooltiptext">{getStatusDescription(g)}</span>
                    </div>
                </Card>
            </Col>
        )}
    </>

    function getStatusDescription({status}: Goal) {
        switch (status) {
            case "reached": return "Цель достигнута"
            case "failed": return "Цель не достигнута"
            case "in_progress": return "Цель вероятно не будет достигнута"
            default: return ""
        }
    }

    function getPercentage({currentValue, minValue, targetValue}: Goal) {
        return Math.round((currentValue - minValue) / (targetValue - minValue) * 100)
    }

    function getBorderColor({status}: Goal) {
        switch (status) {
            case "reached":
                return "border-green-500"
            case "failed":
                return "border-red-500"
            default:
                return "border-yellow-500"
        }
    }

    function getBgColor(goal: Goal, light: boolean = false) {
        switch (goal.status + (light ? "_light" : "")) {
            case "reached":
                return "bg-green-500"
            case "failed":
                return "bg-red-500"
            case "in_progress":
                return "bg-yellow-500"
            case "reached_light":
                return "bg-green-200"
            case "failed_light":
                return "bg-red-200"
            case "in_progress_light":
                return "bg-yellow-200"
            default:
                return ""
        }
    }

}