import {DataValue} from "@services/dataSetApi";

export const paletteColors = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#73c0de",
    "#3ba272",
    "#fc8452",
    "#9a60b4",
    "#ea7ccc",
    //
    //
    // "#22c55e",
    // "#f97316",
    // "#f43f5e",
    // "#713f12",
    // "#bef264",
    // "#0f766e",
    // "#1d4ed8",
    // "#334155",
    // "#14b8a6",
    // "#c084fc",
    // "#c026d3",
    // "#831843",
    // "#94a3b8",
    // "#f87171",
    // "#93c5fd",
    // "#fde68a",
    // "#eab308",
    // "#fda4af",
    // "#9a3412",
    // "#0ea5e9",
]

const colorsPerDimension: {
    [dim: string]: {
        colors: { [val: string]: string }
        count: number
    }
} = {}

export function getDimensionColor(key: string, value: DataValue) {
    let colors = colorsPerDimension[key]
    if (colors === undefined) {
        colors = colorsPerDimension[key] = {
            colors: {},
            count: 0
        }
    }

    const valKey = value?.toString() ?? '<null>'
    let color = colors.colors[valKey]
    if (color === undefined) {
        color = paletteColors[(colors.count++) % paletteColors.length]
        colors.colors[valKey] = color
    }

    return color
}
