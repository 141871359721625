import React from 'react';
import {Flex, Title} from "@tremor/react";
import {DataValue} from "@services/dataSetApi";
import NoChartData from "@components/common/NoChartData";
import {EChartsReact} from "@components/common/EChartsReact";
import {hoursToDurationString} from "@components/common/DurationString";
import {useIssueStatusDurationAvg} from "./StatusBreakdownSummaryTable";

export function StatusTimeBreakdownSummaryChart() {
    const title = "Среднее время нахождения завершенных задач в статусе"
    const {data: response, ...queryResult} = useIssueStatusDurationAvg()

    if (!response || !response.data.length)
        return <NoChartData title={title} {...queryResult} isEmpty={response && !response.data.length} />

    const
        getStatusName = (x: DataValue[]) => x[0] as string,
        getHrsSum = (x: DataValue[]) => x[1] as number

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Title className="mb-3">{title}</Title>
            <EChartsReact
                className="w-full h-full"
                option={{
                    series: [
                        {
                            type: 'pie',
                            radius: "50%",
                            data: response.data.map(x => ({
                                name: getStatusName(x),
                                value: getHrsSum(x),
                            })),
                            colorBy: "data",
                            tooltip: {
                                valueFormatter: x => hoursToDurationString(x as number)
                            },
                            label: {
                                formatter: x => `${x.percent}%`,
                            },
                            labelLayout: {

                                //moveOverlap: 'shiftX'
                            }

                        }
                    ],
                    //legend: {},
                    tooltip: {},
                }}
            />
        </Flex>
    )
}
