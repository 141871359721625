import React from 'react';
import {Flex, Title} from "@tremor/react";
import {useDataSet} from "@utils/useDataSet";
import NoChartData from "@components/common/NoChartData";
import {EChartsReact} from "@components/common/EChartsReact";
import {FilterId, useFilters} from "@components/filters/FiltersContext";

export type SprintMeasurePlanFactChartProps = {
    measure?: 'issuesCount' | 'estimate'
}

const measureConfig = {
    issuesCount: {
        title: 'План/факт по количеству задач за каждый спринт',
        axisName: 'Количество задач',
        measures: {
            total: {
                name: 'total_issues_count',
                title: 'Завершено задач'
            },
            remaining: {
                name: 'remaining_issues_count',
                title: 'Осталось задач'
            },
        },
        percentageMarkLine: {
            labelFormatter: (x: number) => `В среднем закрывается {value|${Number(x.toFixed(1))}%} задач за спринт`
        }
    },
    estimate: {
        title: 'Velocity',
        axisName: 'Количество SP',
        measures: {
            total: {
                name: 'total_estimate_sum',
                title: 'Выполнено SP'
            },
            remaining: {
                name: 'remaining_estimate_sum',
                title: 'Осталось SP'
            },
        },
        percentageMarkLine: {
            labelFormatter: (x: number) => `Velocity в SP: {value|${Number(x.toFixed(1))}}`
        }
    }
}

export function SprintMeasurePlanFactChart({measure = "issuesCount"}: SprintMeasurePlanFactChartProps) {
    const {title, axisName, measures, percentageMarkLine} = measureConfig[measure]
    const filters = useFilters()
    const {data: response, ...queryResult} = useDataSet({
        dataset: 'sprint',
        select: [
            {name: 'sprint_short_name', alias: 'sprint'},
            {name: measures.total.name, alias: 'total'},
            {name: measures.remaining.name, alias: 'remaining'},
            {name: 'sprint_from'},
        ],
        filters: {
            ...filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.IssueTypes]),
            closedSprintsOnly: true,
        },
        orderBy: [
            {name: 'sprint_from'},
        ]
    })

    if (!response || !response.data.length)
        return <NoChartData title={title} {...queryResult} isEmpty={response && !response.data.length} />

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Title className="mb-3">{title}</Title>
            {measure == "estimate" && <EChartsReact
                className="flex-grow h-full w-full"
                option={{
                    grid: {
                        left: 25,
                        right: 5,
                        top: 30,
                        bottom: 0,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: response.data.map(x => x[0] as string),
                        axisLabel: {
                            rotate: 90,
                            fontSize: 10
                        }
                    },
                    yAxis: [
                        {
                            type: 'value',
                            name: axisName,
                            nameLocation: 'middle',
                            nameGap: 30,
                        }
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: measures.total.title,
                            data: response.data.map(x => x[1] as number),
                            yAxisIndex: 0,
                            stack: 'x'
                        },
                        {
                            type: 'bar',
                            name: measures.remaining.title,
                            data: response.data.map(x => x[2] as number),
                            yAxisIndex: 0,
                            stack: 'x'
                        },
                        {
                            type: 'line',
                            name: 'Velocity',
                            data: response.data.map(x => (x[1] as number)),
                            yAxisIndex: 0,
                            smooth: true,
                            symbol: 'none',
                            markLine: {
                                data: [
                                    {
                                        type: 'average'
                                    }
                                ],
                                symbol: ['none', 'arrow'],
                                animation: false,
                                silent: true,
                                label: {
                                    formatter: x => percentageMarkLine.labelFormatter(x.value as number),
                                    position: 'insideEndTop',
                                    rich: {
                                        value: {
                                            fontWeight: 'bolder',
                                        },
                                    }
                                },
                            }
                        },
                    ],
                    legend: {},
                    tooltip: [
                        {
                            trigger: 'axis',
                            valueFormatter: (x) => `${Number((x as number).toFixed(1))}`
                        }
                    ],
                }}
            />}
            {measure != "estimate" && <EChartsReact
                className="flex-grow h-full w-full"
                option={{
                    grid: {
                        left: 25,
                        right: 5,
                        top: 30,
                        bottom: 0,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: response.data.map(x => x[0] as string),
                        axisLabel: {
                            rotate: 90,
                            fontSize: 10
                        }
                    },
                    yAxis: [
                        {
                            type: 'value',
                            name: axisName,
                            nameLocation: 'middle',
                            nameGap: 30,
                        },
                        {
                            type: 'value',
                            axisLabel: {
                                formatter: (x: number) => `${x}%`
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: measures.total.title,
                            data: response.data.map(x => x[1] as number),
                            yAxisIndex: 0,
                            stack: 'x'
                        },
                        {
                            type: 'bar',
                            name: measures.remaining.title,
                            data: response.data.map(x => x[2] as number),
                            yAxisIndex: 0,
                            stack: 'x'
                        },
                        {
                            type: 'line',
                            name: '% выполнения',
                            data: response.data.map(x => 100.0 * (x[1] as number) / ((x[2] as number) + (x[1] as number))),
                            yAxisIndex: 1,
                            smooth: true,
                            symbol: 'none',
                            markLine: {
                                data: [
                                    {
                                        type: 'average'
                                    }
                                ],
                                symbol: ['none', 'arrow'],
                                animation: false,
                                silent: true,
                                label: {
                                    formatter: x => percentageMarkLine.labelFormatter(x.value as number),
                                    position: 'insideEndTop',
                                    rich: {
                                        value: {
                                            fontWeight: 'bolder',
                                        },
                                    }
                                },
                            }
                        },
                    ],
                    legend: {},
                    tooltip: [
                        {
                            trigger: 'axis',
                            valueFormatter: (x) => `${Number((x as number).toFixed(1))}`
                        }
                    ],
                }}
            />}
        </Flex>
    )
}
