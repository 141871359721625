import {useQuery} from "react-query";
import {dataSetApi} from "./dataSetApi";
import {useMemo} from "react";
import {from} from "linq-to-typescript";

export function useFilterValuesQuery(estimatedOnly: boolean = false) {
    const response = useQuery(['filterValues', estimatedOnly], {
        queryFn: ({signal}) => dataSetApi.getFilterValues(estimatedOnly, signal),
        refetchOnWindowFocus: false,
    })

    const valuesMap = useMemo(() => {
        return !response.data ? undefined : {
            projects: from(response.data.projects).toObject(x => x.value),
            epics: from(response.data.epics).toObject(x => x.value),
            issueTypes: from(response.data.issueTypes).toObject(x => x.value),
        }
    }, [response])

    return {
        ...response,
        valuesMap
    }
}