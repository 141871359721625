import './DashboardPage.css'
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from "@tremor/react";
import {RemixiconComponentType, RiSpeedUpLine, RiCrosshairLine, RiBug2Line, RiMicroscopeLine} from "@remixicon/react";
import {ReactNode, useState} from "react";
import {Sidebar, Menu, MenuItem} from 'react-pro-sidebar';
import {useOneTimeEffect} from "@utils/useOneTimeEffect";
import {FilterImage, ToggleSideBarLeftImage, ToggleSideBarRightImage} from "@components/filters/FilterImages";
import {DashboardFiltersView} from "@components/filters/DashboardFiltersView";
import {FilterId, FiltersProvider, useFiltersScope} from "@components/filters/FiltersContext";
import {
    AggregatedStatsTabContent, ExecutionFlowTabContent, OngoingIssuesTabContent,
    QualityTabContent, ScopeForecastTabContent, SprintsTabContent,
    SummaryTabContent, TeamVelocityTabContent, ValueDeliveryTabContent
} from './TabContent';


export default function DashboardPage() {
    return <FiltersProvider>
        <DashboardPageContent />
    </FiltersProvider>
}

function DashboardPageContent() {
    const {scopeFilters} = useFiltersScope()
    const [filtersBarOpen, setFiltersBarOpen] = useState(true);
    const [selectedSectionName, setSelectedSectionName] = useState(sections[0].name)
    const [sidebarCollapsed, setCollapsed] = useState(false);

    const onMenuItemClick = (section: Section) => {
        if (selectedSectionName == section.name)
            setCollapsed(!sidebarCollapsed);

        setSelectedSectionName(section.name);
    }

    const filtersBarWidth = "18vw";
    const sidebarWidth = "18vw";
    const sidebarColapsedWidth = "60px";
    const showFilters = filtersBarOpen && scopeFilters.length > 0

    return <div
        style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "base-line",
            height: "100%",
            position: "relative",
        }}
    >
        <Sidebar collapsed={sidebarCollapsed} width={sidebarWidth} collapsedWidth={sidebarColapsedWidth}>
            <Menu>
                {sections.map(x =>
                    <MenuItem key={x.name} onClick={() => onMenuItemClick(x)}>
                        {<span><x.collapsedIcon style={{width: 20, height: 20, display: 'inline', paddingBottom: "2px"}} /> {!sidebarCollapsed && x.title} </span>}
                    </MenuItem>
                )}
            </Menu>
        </Sidebar>

        <main style={{
            height: "100%",
            width: "100%",
            overflowY: "auto"
        }}>
            {sections.map(x =>
                x.name === selectedSectionName && <TabedContent tabs={x.tabs} />
            )}
        </main>

        <div className="border-black/14 border-solid border-r-[1px] pt-[10px]">
            {showFilters && (
                <div className="mx-[10px] mb-[10px]" style={{minWidth: filtersBarWidth}} >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>Фильтры</span>
                        <button disabled={!scopeFilters.length} onClick={() => setFiltersBarOpen(false)}>
                            <span style={{fontSize: 18, lineHeight: 0}}><ToggleSideBarRightImage /></span>
                        </button>
                    </div>
                    <div className="mt-[10px]">
                        <DashboardFiltersView />
                    </div>
                </div>
            )}
            {!showFilters && (
                <div className="mx-[10px] mb-[10px]" style={{textAlign: "center"}}>
                    <button disabled={!scopeFilters.length} className="mt-1" onClick={() => setFiltersBarOpen(true)}>
                        <span className={"text-tremor-brand"}><ToggleSideBarLeftImage /></span>
                    </button>
                    <button disabled={!scopeFilters.length} className="mt-1" onClick={() => setFiltersBarOpen(true)}>
                        <FilterImage />
                    </button>
                </div>
            )}
        </div>
    </div>
}

type TabedContentProps = {
    tabs: ChartTabDef[]
}

function TabedContent(props: TabedContentProps) {
    const filtersScope = useFiltersScope()

    useOneTimeEffect(() => filtersScope.setScopeFilters(props.tabs[0].filters))

    return <div style={{padding: "0.5em 1em 1em 1em"}}>
        <TabGroup onIndexChange={i => filtersScope.setScopeFilters(props.tabs[i].filters)}>
            <TabList>
                {props.tabs.map(x => <Tab key={x.key}>{x.title}</Tab>)}
            </TabList>
            <TabPanels>
                {props.tabs.map(x => <TabPanel key={x.key} children={x.component}></TabPanel>)}
            </TabPanels>
        </TabGroup>
    </div>
}

type ChartTabDef = {
    key: string
    title: string
    component: ReactNode,
    filters: FilterId[]
}

type Section = {
    name: string,
    title: string,
    tabs: ChartTabDef[],
    collapsedIcon: RemixiconComponentType
}

var sections: Section[] = [
    {
        name: "speed-and-flexibilty",
        title: "Скорость и гибкость",
        collapsedIcon: RiSpeedUpLine,
        tabs: [
            {
                key: 'summary',
                title: 'Сводка',
                component: <SummaryTabContent />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Projects,
                    FilterId.IssueTypes,
                    FilterId.DefectTypes,
                ]
            },
            {
                key: 'value-delivery',
                title: 'Скорость поставки ценности',
                component: <ValueDeliveryTabContent />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Projects,
                    FilterId.IssueTypes,
                ]
            },
            {
                key: 'execution-flow',
                title: 'Анализ потока выполнения',
                component: <ExecutionFlowTabContent />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Projects,
                    FilterId.IssueTypes,
                ]
            },
        ]
    },
    {
        name: "performance",
        title: "Результативность",
        collapsedIcon: RiCrosshairLine,
        tabs: [
            {
                key: 'team-velocity',
                title: 'Скорость работы команд',
                component: <TeamVelocityTabContent />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Projects,
                    FilterId.IssueTypes,
                ]
            },
            {
                key: 'ongoing-issues',
                title: 'Текущие задачи',
                component: <OngoingIssuesTabContent />,
                filters: [
                    FilterId.Projects,
                    FilterId.IssueTypes,
                    FilterId.DefectTypes,
                ]
            },
            {
                key: 'sprints',
                title: 'Результативность спринтов',
                component: <SprintsTabContent />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Projects,
                    FilterId.IssueTypes,
                ]
            },
            {
                key: 'scope-forecast',
                title: 'Прогнозирование завершения работ',
                component: <ScopeForecastTabContent />,
                filters: [
                ]
            },
        ]
    },
    {
        name: "quality",
        title: "Качество",
        collapsedIcon: RiBug2Line,
        tabs: [
            {
                key: 'quality',
                title: 'Качество (дефекты)',
                component: <QualityTabContent />,
                filters: [
                    FilterId.TimeRange,
                    FilterId.Projects,
                    FilterId.DefectTypes,
                ]
            },
        ]
    },
    {
        name: "benchmark",
        title: "Бенчмарк",
        collapsedIcon: RiMicroscopeLine,
        tabs: [
            {
                key: 'aggregated',
                title: 'Сводные показатели',
                component: <AggregatedStatsTabContent />,
                filters: [
                ]
            },
        ]
    }
]