export function maxOrUndef(...values: (number | undefined)[]) {
    if (values.length) {
        let max = values[0]
        for (let i = 1; i < values.length; i++) {
            const val = values[i]
            if (max === undefined) {
                max = val
            } else {
                if (val !== undefined && val > max) {
                    max = val
                }
            }
        }
        return max
    }
}

export function roundToSignificantDigits(val: number, digits: number) {
    const log10Floor = Math.floor(Math.log10(val * Math.sign(val)))
    digits = Math.max(log10Floor + 1, digits)
    const mul = Math.pow(10, digits - log10Floor - 1)
    return Math.round(val * mul) / mul
}