import React from 'react';
import {Flex, Title} from "@tremor/react";
import {from} from "linq-to-typescript";
import {DataValue} from "@services/dataSetApi";
import {useDataSet} from "@utils/useDataSet";
import {getDimensionColor, paletteColors} from "@utils/colorPaletteUtils";
import {addIntervalToDate} from "@utils/dateUtils";
import {FilterSpecDto} from "@services/sharedApiModels";
import NoChartData from "@components/common/NoChartData";
import {EChartsReact} from "@components/common/EChartsReact";
import {FilterId, useFilters} from "@components/filters/FiltersContext";

type CumulativeFlowDiagramChartProps = {
    compact?: boolean
    showLegend?: boolean,
    showDataZoom?: boolean,
    showToolBox?: boolean
}

export const useStatusUpdateQuery = (interval: 'month' | 'week' | 'day', filters: FilterSpecDto) => useDataSet({
    dataset: 'issue_status_update',
    select: [
        {name: 'ts', args: [{name: 'trunc_to', value: interval}], alias: 'ts1'},
        {name: 'diff_sum_cum'},
        {name: 'issue_status_sort_order'},
        {name: 'issue_status_name'},
        {name: 'arrival_sum'},
        {name: 'out_sum'},
        {name: 'issue_status_is_final'}
    ],
    filters,
    orderBy: [
        {name: 'ts1'},
        {name: 'issue_status_sort_order'},
    ],
})

export function CumulativeFlowDiagramChart({compact = false, showLegend = !compact, showDataZoom = !compact, showToolBox = !compact}: CumulativeFlowDiagramChartProps) {
    const title = "Кумулятивная диаграмма потока"
    const interval = compact ? 'week' : 'day'
    const filters = useFilters()
    const filterSpec = filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.IssueTypes])

    const {data: response, ...queryResult} = useStatusUpdateQuery(interval, filterSpec)

    const {data: initialSumCumResponse, ...initialSumCumqueryResult} = useDataSet({
        dataset: 'issue_status_update',
        select: [
            {name: 'diff_sum'},
            {name: 'issue_status_name'},
            {name: 'issue_status_sort_order'},
        ],
        filters: {
            ...filterSpec,
            timeRange: {
                end: filterSpec.timeRange?.start || '1900-01-01T00:00:00'
            }
        },
    })

    if (!response || !response.data.length)
        return <NoChartData title={title} {...queryResult} isEmpty={response && !response.data.length} />

    if (!initialSumCumResponse)
        return <NoChartData title={title} {...initialSumCumqueryResult} />

    const
        getTs = (x: DataValue[]) => x[0] as number,
        getDiffSum = (x: DataValue[]) => x[1] as number,
        getSortOrder = (x: DataValue[]) => x[2] as number,
        getStatusName = (x: DataValue[]) => x[3] as string

    const minDate = new Date((response.data[0][0] as number) * 1000)
    const maxDate = new Date((response.data[response.data.length - 1][0] as number) * 1000)
    const statuses = from(response.data)
        .select(x => ({name: getStatusName(x), order: getSortOrder(x)}))
        .concatenate(from(initialSumCumResponse.data).select(x => ({name: x[1] as string, order: x[2] as number})))
        .distinct((x, y) => x.name === y.name && x.order === y.order)
        .orderByDescending(x => String(x.order).padStart(4, '0'))
        .select(x => x.name)
        .toArray()

    const initialSumCum: number[] = []
    statuses.forEach(x => {
        initialSumCum.push((initialSumCumResponse.data.find(d => d[1] === x)?.[0] || 0) as number)
    })

    const ds: (string | Date | number)[][] = []
    ds.push(['date', ...statuses])

    const generateDates = function* () {
        let date = minDate
        let row = 1
        yield {date: new Date(date.getTime()), row}
        while (date <= maxDate) {
            yield {date: new Date(addIntervalToDate(date, interval)), row: ++row}
        }
    };

    const joined = from(generateDates())
        .groupJoin(from(response.data),
            x => x.date.getTime(),
            x => getTs(x) * 1000,
            (x, y) => ({
                date: x.date,
                row: x.row,
                dsValues: y.reduce((acc, v) => {
                    acc[getStatusName(v)] = getDiffSum(v);
                    return acc
                }, {} as {[key: string]: number}),
            }))

    for (const {date, row, dsValues} of joined) {
        const values: number[] = []
        statuses.forEach((status, i) => {
            const initial = initialSumCum[i]
            const dsVal = dsValues[status]
            values.push(dsVal !== undefined ? (dsVal + initial) : (row <= 1 ? initial : ds[row - 1][i + 1] as number))
        })
        ds.push([date, ...values])
    }

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Title className="mb-3">{title}</Title>
            <EChartsReact
                className="h-full w-full flex-grow"
                option={{
                    grid: {
                        left: 25,
                        right: showDataZoom ? 45 : 15,
                        top: showLegend ? 50 : 10,
                        bottom: showDataZoom ? 50 : 10,
                        containLabel: true,
                    },
                    dataset: {
                        source: ds
                    },
                    xAxis: {
                        type: 'time',
                    },
                    yAxis: {
                        type: 'value',
                        name: 'Количество задач',
                        nameLocation: 'middle',
                        nameGap: 40,
                    },
                    color: paletteColors.reverse(),
                    series: statuses.map((s, i) => ({
                        type: 'line',
                        stack: 'x',
                        name: s,
                        encode: {
                            x: 'date',
                            y: s
                        },
                        showSymbol: false,
                        areaStyle: {},
                        colorBy: "series",
                        smooth: false,
                        color: getDimensionColor('issue_status_name', statuses[i])
                    })),
                    legend: showLegend ? {
                        data: statuses.reverse()
                    } : undefined,
                    dataZoom: [
                        {
                            filterMode: 'none',
                            xAxisIndex: [0],
                            type: 'slider',
                            show: showDataZoom,
                        },
                        {
                            filterMode: 'none',
                            xAxisIndex: [0],
                            type: 'inside'
                        },
                        {
                            filterMode: 'none',
                            yAxisIndex: [0],
                            type: 'slider',
                            show: showDataZoom,
                        },
                        {
                            filterMode: 'none',
                            yAxisIndex: [0],
                            type: 'inside'
                        },
                    ],
                    tooltip: [
                        {
                            trigger: 'axis',
                            order: 'seriesDesc',
                            axisPointer: {
                                type: 'cross'
                            },
                        }
                    ],
                    toolbox: showToolBox ? {
                        feature: {
                            dataZoom: {
                                filterMode: 'none'
                            }
                        }
                    } : undefined
                }}
            />
        </Flex>
    )
}