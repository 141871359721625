import React from 'react';
import {Flex, Title} from "@tremor/react";
import NoChartData from "@components/common/NoChartData";
import {EChartsReact} from "@components/common/EChartsReact";
import {FilterId, useFilters} from "@components/filters/FiltersContext";
import {useDataSet} from "@utils/useDataSet";

export function DefectsReportedFixedChart() {
    const title = "Дефекты: заведенно/закрыто"
    const filters = useFilters()
    const {data: response, ...queryResult} = useDataSet({
        dataset: 'issue_in_status',
        select: [
            {name: 'issue_status_from_trunc', alias: 'ts', args: [{name: 'trunc_to', value: 'month'}]},
            {name: 'created_issues_count', alias: 'reported'},
            {name: 'resolved_issues_count', alias: 'resolved'},
        ],
        filters: filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.DefectTypes]),
        orderBy: [
            {name: 'ts'},
        ]
    })

    if (!response || !response.data.length)
        return <NoChartData title={title} {...queryResult} isEmpty={response && !response.data.length} />

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Title className="mb-3">{title}</Title>
            <EChartsReact
                className="flex-grow h-full w-full"
                option={{
                    grid: {
                        left: 10,
                        right: 5,
                        top: 30,
                        bottom: 0,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'time',
                    },
                    yAxis: [
                        {
                            type: 'value',
                            // name: 'Количество дефектов',
                            // nameLocation: 'middle',
                            // nameGap: 40,
                        },
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: 'Заведено',
                            data: response.data.map(x => [new Date((x[0] as number) * 1000), x[1] as number]),
                            label: {
                                show: true,
                                position: "top"
                            }
                        },
                        {
                            type: 'bar',
                            name: 'Закрыто',
                            data: response.data.map(x => [new Date((x[0] as number) * 1000), x[2] as number]),
                            label: {
                                show: true,
                                position: "top"
                            }
                        },
                    ],
                    legend: {},
                    tooltip: [
                        {
                            trigger: 'axis',
                            valueFormatter: (x) => `${Number((x as number).toFixed(1))}`
                        }
                    ],
                }}
            />
        </Flex>
    )
}
