import {Title} from "@tremor/react";
import React, {TableHTMLAttributes} from "react";
import {TableView} from "@components/TableView";
import {hoursToDurationString} from "@components/common/DurationString";
import {FilterId, useFilters} from "@components/filters/FiltersContext";

export function CompletedIssuesTableView(props: TableHTMLAttributes<HTMLTableElement>) {
    const filters = useFilters()

    return <>
        <Title>Завершенные задачи</Title>
        <TableView
            pageSize={10}
            query={{
                dataset: 'issue',
                filters: {
                    ...filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.IssueTypes]),
                    completedOnly: true,
                    excludeCanceled: true,
                },
                select: [
                    {name: 'issue_id'},
                    {name: 'issue_key'},
                    {name: 'issue_summary'},
                    {name: 'issue_url'},
                    {name: 'issue_created_at'},
                    {name: 'issue_resolved_at'},
                    {name: 'project_name'},
                    {name: 'issue_type_name'},
                    {name: 'issue_cycle_time_hrs'},
                    {name: 'issue_lead_time_hrs'},
                ],
                orderBy: [
                    {name: 'issue_created_at', desc: true}
                ]
            }}
            columns={[
                {
                    name: 'issue_key',
                    formatter: (val, row) => <a rel="noreferrer" target="_blank" href={row[3] as string}>{val}</a>
                },
                {name: 'issue_summary'},
                {name: 'issue_created_at'},
                {name: 'issue_resolved_at'},
                {name: 'project_name'},
                {name: 'issue_type_name'},
                {name: 'issue_cycle_time_hrs', formatter: v => hoursToDurationString(v as number)},
                {name: 'issue_lead_time_hrs', formatter: v => hoursToDurationString(v as number)},
            ]}
            {...props}
        />
    </>
}