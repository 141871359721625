import {useDataSet} from "@utils/useDataSet";
import {FilterId, useFilters} from "@components/filters/FiltersContext";

const percentile = 85

export type PrMeasureSnapshotData = {
    prAverageLifetimeHours: number
    percentile: number
}

export type PrMeasureDynamicsData = {
    percentile: number
    items: PrMeasureDynamicsDataItem[]
}

export type PrMeasureDynamicsDataItem = {
    ts: number
    prAverageLifetimeHours: number
}

export function usePrMeasureSnapshotDataSet() {
    const filters = useFilters()

    const {data, ...queryResult} = useDataSet({
        dataset: 'pr_average_lifetime',
        filters: {
            ...filters.getFilterSpec([FilterId.TimeRange])
        },
        select: [
            {name: 'merged_pr_lifetime_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
        ]
    })

    if (data) {
        const snapshot: PrMeasureSnapshotData = {
            prAverageLifetimeHours: data.data[0][0] as number,
            percentile: percentile as number,
        }

        return {data: snapshot, ...queryResult}
    }

    return {data: undefined, ...queryResult}
}

export function usePrMeasureDynamicsDataSet() {
    const filters = useFilters()

    const {data, ...queryResult} = useDataSet({
        dataset: 'pr_average_lifetime',
        filters: {
            ...filters.getFilterSpec([FilterId.TimeRange]),
            includeItemsStartedBefore: false,
            includeItemsFinishedAfter: true
        },
        select: [
            {name: 'pr_merged_at_trunc', alias: 'ts', args: [{name: 'trunc_to', value: 'month'}]},
            {name: 'merged_pr_lifetime_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
        ]
    })

    if (data && data.data.length > 0) {
        const ds: PrMeasureDynamicsData = {
            percentile,
            items: data.data.map(x => ({
                ts: x[0] as number,
                prAverageLifetimeHours: x[1] as number,
            } as PrMeasureDynamicsDataItem))
        }

        return {data: ds, isEmpty: false, ...queryResult}
    }

    return {data: undefined, isEmpty: data && data.data.length === 0, ...queryResult}
}
